/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSupportTypeCategories } from '../fn/codelist/get-support-type-categories';
import { GetSupportTypeCategories$Params } from '../fn/codelist/get-support-type-categories';
import { GetSupportTypeCategoriesResponse } from '../models/get-support-type-categories-response';


/**
 * Služby pre načítanie číselníkov a statických dát
 */
@Injectable({ providedIn: 'root' })
export class CodelistService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSupportTypeCategories()` */
  static readonly GetSupportTypeCategoriesPath = '/client/getSupportTypeCategories';

  /**
   * Načítanie zoznamu typov kategórií pre kontaktný formulár.
   *
   * Služba načíta zoznam typov kategórií pre kontaktný formulár
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupportTypeCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportTypeCategories$Response(params?: GetSupportTypeCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<GetSupportTypeCategoriesResponse>> {
    return getSupportTypeCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * Načítanie zoznamu typov kategórií pre kontaktný formulár.
   *
   * Služba načíta zoznam typov kategórií pre kontaktný formulár
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupportTypeCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportTypeCategories(params?: GetSupportTypeCategories$Params, context?: HttpContext): Observable<GetSupportTypeCategoriesResponse> {
    return this.getSupportTypeCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetSupportTypeCategoriesResponse>): GetSupportTypeCategoriesResponse => r.body)
    );
  }

}
